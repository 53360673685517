import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { RoomService } from '../services/room.service';
import { House } from '../entity/house';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private roomService: RoomService) { }

  ngOnInit() { }

  getHouse(): Observable<House> {
    return this.roomService.getActiveHouse();
  }
}
