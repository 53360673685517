<mat-grid-list *ngIf="(isDesktop() | async); else mobile" cols="{{ getColumns() | async }}">
  <mat-grid-tile *ngFor="let img of (getImages() | async)">
    <mat-card class="gallery-card">
      <img mat-card-image src="{{ img?.image }}" alt="{{ img?.alt }}" />
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
<ng-template #mobile>
  <div class="carousel">
    <ng-image-slider  [infinite]="true"
                      [imagePopup]="true"
                      [imageSize]="{width: 300, height: 300}"
                      [animationSpeed]="0.25"
                      [images]="(getImages() | async)" #nav>
    </ng-image-slider>
  </div>
</ng-template>