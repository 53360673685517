import { Component, OnInit } from '@angular/core';
import { RoomService } from '../services/room.service';
import { Observable } from 'rxjs';
import { Room } from '../entity/room';
import { Router } from '@angular/router';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})
export class RoomComponent implements OnInit {

  constructor(
    private roomService: RoomService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  getRooms(): Observable<Array<Room>> {
    return this.roomService.getRentableRooms();
  }

  apply(id: number) {
    this.router.navigate(['contact', id]);
  }
}
