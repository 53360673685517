import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { RoomService } from '../services/room.service';
import { Room } from '../entity/room';
import { Observable, of } from 'rxjs';
import { Application } from '../entity/application';
import { Renter } from '../entity/renter';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public mask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  application: Application = {houseCode: '', rooms: [], renters: [{ id: 1, name: '', phone: '', email: '', comment: ''}]};
  rooms: Array<Room>;

  constructor(
    private route: ActivatedRoute,
    private roomService: RoomService
  ) { }

  ngOnInit() {
    this.roomService.getRooms().subscribe(room => this.rooms = room.filter(r => r.rentable == true));
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => of(+params.get('id')))
    ).subscribe(id => {
      this.rooms.map(room => {
        room.checked = room.id === id;
        return room;
      });
    });
  }

  getRenters(): Observable<Array<Renter>> {
    return of(this.application.renters);
  }

  getRooms(): Observable<Array<Room>> {
    return of(this.rooms);
  }

  addNewRenter(): void {
    this.application.renters.push({ id: this.application.renters.length + 1, name: '', phone: '', email: '', comment: ''});
  }

  reset(): void {
    this.application = {houseCode: '', rooms: [], renters: [{ id: 1, name: '', phone: '', email: '', comment: ''}]};
  }

  submit(): void {
    this.roomService.getActiveHouse().subscribe(house => this.application.houseCode = house.code);
    this.rooms.filter(room => room.checked === true).forEach( room => this.application.rooms.push(room));
    console.log(this.application);
    this.reset();
  }
}
