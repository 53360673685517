import { Component, OnInit } from '@angular/core';
import { RoomService } from '../services/room.service';
import { Observable, of } from 'rxjs';
import { Img } from '../entity/img';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  cols: number;

  constructor(private roomService: RoomService) { }

  ngOnInit() {
  }

  getImages(): Observable<Array<Img>> {
    return of(this.roomService.getHouseImages());
  }

  getColumns(): Observable<number> {
    this.cols = this.roomService.getHouseImages().length;
    if(this.cols >= 3) {
      this.cols = 3;
    }
    return of(this.cols);
  }

  isDesktop(): Observable<boolean> {
    if (window.screen.width <= 768) {
      return of(false);
    } else {
      return of(true);
    }
  }
}
