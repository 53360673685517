<mat-card class="room-container" *ngFor="let room of (getRooms() | async)">
  <mat-card-content>
    <ng-image-slider *ngIf="room?.imgList"
      [infinite]="false"
      [imagePopup]="true"
      [imageSize]="{width: 300, height: 300}"
      [animationSpeed]="0.25"
      [images]="room?.imgList" #nav>
    </ng-image-slider>
    <mat-list>
      <mat-list-item>{{room.name}}</mat-list-item>
      <mat-list-item>{{room.desc}}</mat-list-item>
      <mat-list-item>{{room.price | currency}}</mat-list-item>
    </mat-list>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="accent" (click)="apply(room?.id)">Apply</button>
  </mat-card-actions>
</mat-card>