import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { House } from '../entity/house.js';
import { Room } from '../entity/room.js';
import { Img } from '../entity/img.js';
import * as data from '../../assets/Houses.json';

@Injectable({
  providedIn: 'root'
})

export class RoomService {

  houses: Array<House> = [];
  activeHouse: House;

  constructor() {
    this.houses = ((data as any).default as Array<House>);
  }

  areRoomsAvailable(): Observable<boolean> {
    return of(typeof(this.activeHouse.rooms) !== 'undefined' && this.activeHouse.rooms.filter(room => room.rentable == true).length > 0);
  }

  getRooms(): Observable<Array<Room>> {
    return of(this.activeHouse.rooms);
  }

  getRentableRooms(): Observable<Array<Room>> {
    return of(this.activeHouse.rooms.filter(room => room.rentable == true));
  }

  getRoom(id: number): Observable<Room> {
    return of(this.activeHouse.rooms.find(room => room.id === id));
  }

  getHouses(): Observable<Array<House>> {
    return of(this.houses);
  }

  getActiveHouse(): Observable<House> {
    return of(this.activeHouse);
  }

  getHouseImages(): Array<Img> {
    return this.activeHouse.imgList;
  }

  setActiveHouse(code: string): void {
    console.log('We are now changing the house to ' + code);
    this.activeHouse = this.houses.find(house => house.code === code);
  }
}
