<mat-sidenav-container class="content" (backdropClick)="close()" [hasBackdrop]="true">
  <mat-sidenav #sidenav (keydown.escape)="close()" disableClose>
    <p><button mat-button (click)="route('about')">About</button></p>
    <p><button mat-button (click)="route('gallery')">Gallery</button></p>
    <p *ngIf="areRoomsAvailable() | async"><button mat-button (click)="route('rooms')">Available Rooms</button></p>
    <p><button mat-button (click)="route('contact')">Contact Us</button></p>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <button (click)="sidenav.open()" mat-icon-button aria-label="Menu button for the side nav">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="fill-remaining-space"></span>
      <div *ngIf="(getHouses() | async).length > 1; then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <div style="text-align: center">
          <span class="mat-headline">{{title}}</span><br />
          <button mat-button [matMenuTriggerFor]="house">{{ (getHouse() | async)?.name }}</button>
          <mat-menu #house="matMenu" >
            <button mat-menu-item
                    *ngFor='let house of (getHouses() | async)'
                    (click)="setActiveHouse(house?.code)">{{ house?.name }}</button>
          </mat-menu>
        </div>
      </ng-template>
      <ng-template #elseBlock>
        <div style="text-align: center">
          <span class="mat-headline">{{title}}</span><br />
          <button mat-button>{{ (getHouse() | async)?.name }}</button>
        </div>
      </ng-template>
      <span class="fill-remaining-space"></span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>