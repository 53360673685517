import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { RoomService } from './services/room.service';
import { Observable } from 'rxjs';
import { House } from './entity/house';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Amazing House Rentals';

  constructor(
    private router: Router,
    private roomService: RoomService
  ) {
    roomService.setActiveHouse('HAM');
  }

  @ViewChild('sidenav') sidenav: MatSidenav;

  changeHouse(name: string) {
    this.roomService.setActiveHouse(name);
  }

  getHouses(): Observable<Array<House>> {
    return this.roomService.getHouses();
  }

  getHouse(): Observable<House> {
    return this.roomService.getActiveHouse();
  }

  areRoomsAvailable(): Observable<boolean> {
    return this.roomService.areRoomsAvailable();
  }

  setActiveHouse(code: string): void {
    this.roomService.setActiveHouse(code);
    this.route('about');
  }

  close() {
    this.sidenav.close();
  }

  route(target: string) {
    this.close();
    this.router.navigate([target]);
  }

  ngOnInit() { }
}
