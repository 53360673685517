<div class="container">
  <span class="mat-list-item">{{ (getHouse() | async)?.desc }}</span>
  <br />
  <img class="center-img" src="{{ (getHouse() | async)?.img }}" /><br />
  <mat-list>
    <mat-list-item *ngFor="let sellPoint of (getHouse() | async)?.sellPoints"
                    [className]="sellPoint.isBold? 'bold mat-list-item listSpacing' : 'mat-list-item listSpacing'">
        {{ sellPoint.text }}
    </mat-list-item>
  </mat-list>
</div>