<form class="contact">
  <div *ngFor="let renter of (getRenters() | async)">
    <span class="mat-headline" style="margin: 2vh 2vw">Renter #{{ renter?.id }}</span><br />
    <mat-form-field class="half-width">
      <mat-label>Name</mat-label>
      <input matInput name="name" [(ngModel)]="renter.name" />
    </mat-form-field>
    <mat-form-field class="half-width">
      <mat-label>Phone Number</mat-label>
      <input matInput name="phone" [textMask]="{mask: mask}" [(ngModel)]="renter.phone" />
    </mat-form-field>
    <mat-form-field class="half-width">
      <mat-label>Email</mat-label>
      <input matInput name="email" [(ngModel)]="renter.email" [email]="true" />
    </mat-form-field>
    <mat-form-field class="half-width">
      <mat-label>Comments</mat-label>
      <textarea matInput name="comment" [(ngModel)]="renter.comment"></textarea>
    </mat-form-field>
  </div>
  <div>
    <button mat-raised-button (click)="addNewRenter()">Add a person</button>
    <button mat-raised-button (click)="reset()">Reset</button>
    <button mat-raised-button (click)="submit()">Submit</button>
  </div>

  <div *ngFor="let room of (getRooms() | async)">
    <br />
    <div>
      <mat-grid-list cols="3" rowHeight="40vh">
        <mat-grid-tile>
          <div class="carousel">
            <ng-image-slider  [infinite]="false"
                              [imagePopup]="false"
                              [imageSize]="{width: 300, height: 300}"
                              [animationSpeed]="0.25"
                              [images]="room?.imgList" #nav>
            </ng-image-slider>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <table style="width: 100%; margin: 0 auto auto auto;">
            <tr>
              <th style="display: table-cell; vertical-align: top;">Name:</th>
              <td>{{room?.name}}</td>
            </tr>
            <tr>
              <th style="display: table-cell; vertical-align: top;">Desc:</th>
              <td>{{room?.desc}}</td>
            </tr>
            <tr *ngIf="room?.price">
              <th style="display: table-cell; vertical-align: top;">Price:</th>
              <td>{{room?.price | currency}}</td>
            </tr>
          </table>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-checkbox [(ngModel)]="room.checked" [ngModelOptions]="{standalone: true}">
            Apply for this room
          </mat-checkbox>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</form>